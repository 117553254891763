import 'vant/lib/index.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant';
Vue.use(vant)
import "swiper/swiper.min.css"

import api from "./util/api.js"
Vue.prototype.$api = api

import store from "./store/index"

// import 'amfe-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
import axios from 'axios'
import Vue from 'vue'

let urlApi = "http://api.cnbaolai.com/Index"
// let urlApi = "/api"
let load = ''
// let timer = null
export function request(config) {
	const inter = axios.create({
		baseURL: urlApi,
		timeout: 0,
		headers: { 'content-type': 'application/x-www-form-urlencoded' }
	})

	inter.interceptors.request.use(config => {
		if (config.showLoading) {
			load = Vue.prototype.$loading({
				lock: true
			})
		}
		const data = config.data
		if (config.method === 'post' && data) {
			const ret = []
			Object.keys(data).forEach(ele => ret.push(`${ele}=${data[ele]}`))
			config.data = ret.join('&')
		}
		if (config.method === 'get' && data) {
			const ret = []
			Object.keys(data).forEach(ele => ret.push(`${ele}=${data[ele]}`))
			config.data = ret.join('&')
			config.url = config.url + '?' + config.data
		}
		// console.log(config,"config")
		return config
	})
	inter.interceptors.response.use(
		res => {
			// closeLoad()
			load.close()
			// console.log('接口',res);
			return res.data
		},
		(err) => {
			// closeLoad()
			load.close()
			Vue.prototype.$Message.error({
				title: '错误',
				message: '请求失败，请检查您的网络'
			})
			return err
		}
	)

	return inter(config)
}
export function uploadFile(config) {
	const inter = axios.create({
		baseURL: urlApi,
		timeout: 0
	})

	inter.interceptors.request.use(config => {
		// load = Vue.prototype.$loading({
		//   lock: true
		// })

		config.method = 'post'
		config.headers = {
			'content-type': 'application/x-www-form-urlencoded'
		}

		return config
	})

	inter.interceptors.response.use(
		res => {
			// closeLoad()
			// load.close()
			return res.data
		},
		(err) => {
			// closeLoad()
			// load.close()
			Vue.prototype.$Message.error({
				title: '错误',
				message: '文件上传失败'
			})
			return err
		}
	)

	return inter(config)
}
export function request1(config) {
	const inter = axios.create({
		baseURL: urlApi,
		timeout: 0,
		headers: { 'content-type': 'application/x-www-form-urlencoded'}
	})

	inter.interceptors.request.use(config => {
		load = Vue.prototype.$loading({
			lock: true
		})

		const data = config.data
		if (config.method === 'get' && data) {
			const ret = []
			Object.keys(data).forEach(ele => ret.push(`${ele}=${data[ele]}`))
			config.data = ret.join('&')
			config.url = config.url + '?' + config.data
		}
		// console.log(config,"config")

		return config
	})
	inter.interceptors.response.use(
		res => {
			load.close()
			return res.data
		},
		() => {
			load.close()
			Vue.prototype.$Message.error({
				title: '错误',
				message: '请求失败，请检查您的网络'
			})
		}
	)

	return inter(config)
}
//请求结束后关闭加载效果
// function closeLoad() {
//   if (!timer) {
//     load.close()
//   } else {
//     clearTimeout(timer)
//     timer = setTimeout(() => {
//       load.close()
//       clearTimeout(timer)
//       timer = null
//     }, 400)
//   }
// }

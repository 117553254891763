import { request, uploadFile } from './request'

//首页
export function index(data, Headers) {
  return request({
    url: '/index',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//关于宝涞
export function about(data, Headers) {
  return request({
    url: '/about',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//荣誉列表
export function honorList(data, Headers) {
  return request({
    url: '/honor_list',
    method: 'post',
    data,
    Headers,
    showLoading: false
  })
}
//产品首页
export function product(data, Headers) {
  return request({
    url: '/product',
    method: 'post',
    data,
    Headers,
    showLoading: false
  })
}
//产品列表
export function productList(data, Headers) {
  return request({
    url: '/productList',
    method: 'post',
    data,
    Headers,
    showLoading: false
  })
}
//产品详情
export function productDetail(data, Headers) {
  return request({
    url: '/productDetail',
    method: 'post',
    data,
    Headers,
    showLoading: false
  })
}
//新闻资讯
export function newsIndex(data, Headers) {
  return request({
    url: '/news_index',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//新闻列表
export function newsList(data, Headers) {
  return request({
    url: '/news_list',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//新闻详情
export function newsDetail(data, Headers) {
  return request({
    url: '/news_detail',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//下属公司
export function company(data, Headers) {
  return request({
    url: '/company',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//投资者关系
export function partner(data, Headers) {
  return request({
    url: '/partner',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//招贤纳士
export function recruitment(data, Headers) {
  return request({
    url: '/recruitment',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//联系我们
export function contacts(data, Headers) {
  return request({
    url: '/contacts',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//头脚
export function body(data, Headers) {
  return request({
    url: '/body',
    method: 'post',
    data,
    Headers,
    showLoading: true
  })
}
//备案
export function icp(data, Headers) {
  return request({
    url: '/icp',
    method: 'post',
    data,
    Headers,
    showLoading: false
  })
}

//留言
export function feedback(data, Headers) {
  return request({
    url: '/feedback',
    method: 'post',
    data,
    Headers,
    showLoading: false
  })
}
//上传文件
export function upload(data, Headers) {
  return uploadFile({
    url: '/System/upload',
    method: 'post',
    data
  })
}

<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
export default {
  created(){
    // document.documentElement.scrollTop = 0;
			if (JSON.parse(sessionStorage.getItem("state"))) {
				this.$store.commit("GET_STORAGE", {
					menu: JSON.parse(sessionStorage.getItem("state")),
				});
			}
			//监听页面刷新
			window.addEventListener("beforeunload", () => {
				sessionStorage.setItem("state", JSON.stringify(this.$store.state));
			});
  },
  
}
</script>

<style lang="css" src="./assets/common.css">
/* 小型设备（纵向平板电脑和大型手机，600 像素及以上） */
*{-webkit-text-size-adjust: none;} 
body {
  -webkit-text-size-adjust: 100% !important;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
// import newDetail from '../views/lanhu_32xinwenxiangqing/index.vue'
// import contact from '../views/lanhu_51lianxiwomen/index.vue'
// import Investor from '../views/lanhu_81touzizheguanxi/index.vue'
// import invite from '../views/lanhu_71zhaoxiannazhu/index.vue'
// import product from '../views/lanhu_61chanpinzhongxin/index.vue'
// import productDetail from '../views/lanhu_62chanpinxiangqing/index.vue'
// import aboutUs from '../views/lanhu_21guanyuwomen/index.vue'
// import news from '../views/lanhu_31xinwenzixun/index.vue'
// import companies from '../views/lanhu_41xiashugongsi/index.vue'
// import index from '../views/lanhu_11shouye/index.vue'

Vue.use(VueRouter)
//路由懒加载
const newDetail = ()=>import("../views/lanhu_32xinwenxiangqing/index.vue")
const contact = ()=>import("../views/lanhu_51lianxiwomen/index.vue")
const Investor = ()=>import("../views/lanhu_81touzizheguanxi/index.vue")
const invite = ()=>import("../views/lanhu_71zhaoxiannazhu/index.vue")
const product = ()=>import("../views/lanhu_61chanpinzhongxin/index.vue")
const productDetail = ()=>import("../views/lanhu_62chanpinxiangqing/index.vue")
const aboutUs = ()=>import("../views/lanhu_21guanyuwomen/index.vue")
const news = ()=>import("../views/lanhu_31xinwenzixun/index.vue")
const companies = ()=>import("../views/lanhu_41xiashugongsi/index.vue")
const index = ()=>import("../views/lanhu_11shouye/index.vue")

 
// hack router push callback
// [解决 vue-router跳转相同路径报错 ]
const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

const routes = [
    {
    path: '/',
    component: index,
	//vue异步组件实现懒加载
	// component: resolve=>(require(["../views/lanhu_11shouye/index.vue"],resolve)) } ] })
  },
  // {
  //   path: '/index',
  //   name: 'index',
  //   component: index,
  //   meta:{
  //     title:""
  //   }
  // },
  {
    path: '/newDetail/:news_id',
    name: 'newDetail',
    component: newDetail
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/Investor',
    name: 'Investor',
    component: Investor
  },
  {
    path: '/invite',
    name: 'invite',
    component: invite
  },
  {
    path: '/product/:id',
    name: 'product',
    component: product
  },
  {
    path: '/productDetail/:cid/:id',
    name: 'productDetail',
    component: productDetail
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/news/:id',
    name: 'news',
    component: news
  },
  {
    path: '/companies/:id',
    name: 'companies',
    component: companies
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		// 初始状态或更新后的状态，读取方式：this.$store.state.businessInfo.id;
		//这里存放着需要管理的变量信息
		lang: 'zh_ch', //zh_ch 简体中文 en_gb 英文（全球）
	},
	mutations: {
		// 更新状态内容：this.$store.commit('GET_CREDID_INFO', businessInfo);
		GET_LANG(state, newInfo) {
			state.lang = newInfo
		},
		GET_STORAGE(state, newInfo) {
			state.lang = newInfo.menu.lang
		},
	},
	getters: {
		getLang(state) {
			return state.lang
		},
	},
	actions: {
		//触发状态更新，当需要更新多个状态的时候使用，如this.$store.dispatch('getBusinessInfo', newInfo);
		// getBusinesInfo({ commit, state }, newInfo) {
		//    commit('GET_USER_INFO',newInfo.userInfo)
		// }
	}
})